@import "src/themes/daikiri/styles/index.scss";
.input-mock {
  display: flex;
  align-items: baseline;
  width: 100%;
  padding: space(0.75);
  border-radius: space(0.25);
  font-size: 14px;
  transition: ease-in-out 0.2s border;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: getvar($typography, "font-family-body");
  background-color: white;
  border: 1px solid getvar($colors, "neutral", "300");

  &.single {
    background-color: getvar($colors, "neutral", "100") !important;
    border: 1px solid getvar($colors, "neutral", "500") !important;
    color: getvar($colors, "neutral", "500") !important;
    font-size: 13px;
  }

  span {
    color: getvar($colors, "neutral", "500") !important;
  }

  &.hasError {
    border: 1px solid getvar($colors, "error", "600");
  }

  .Input__label-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row !important;

    .Input__label {
      text-align: left;
      display: block;
    }
  }
}

.Input.Input--label-input__input {
  display: flex;
  flex-basis: 100%;
  flex: 2;
  align-items: baseline;

  .Input__wrapper {
    width: 100%;
    display: flex;
    flex-basis: 100%;
    flex: 2;
  }
  .Input__element {
    padding: 0 !important;
    margin-left: 2px;
    border: none;
    color: getvar($colors, "neutral", "800");
  }
  .Input__error-message {
    display: none !important;
  }
}

.Input__error-message_custom {
  display: flex;
  margin-top: space(0.25);
  margin-bottom: space(0.5);
  align-items: flex-start;
  color: getvar($colors, "error", "600");

  svg {
    color: getvar($colors, "error", "600");
    margin-right: space(0.25);
    min-height: 14px;
    min-width: 14px;
    margin-top: 2px;
  }
}
.Alert.Alert--customReference__warning {
  margin-top: space(1);
  .Alert--left {
    margin-bottom: 0 !important;
    align-items: center !important;
  }
}
