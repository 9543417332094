@import "src/themes/daikiri/styles/index.scss";
.templates-tabs-content-container {
  width: 100%;
  max-height: 323px;
  overflow: auto;
  position: relative;

  .user-templates__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    overflow: hidden;
    padding-top: 70px;
    padding-left: 4px;
    position: relative;
    top: -30px;
    margin-top: 9px;
    padding-bottom: 3px;
  }
  .switch_container {
    background-color: getvar($colors, "primary", "50");
    border-radius: 8px;
    padding: 16px;
    margin-top: 26px;

    .Input {
      padding-top: 8px;
      gap: 2px;
      display: flex;
      flex-direction: column;

      ::placeholder {
        color: getvar($colors, "neutral", "600");
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .switch__span {
      margin-left: 16px;
      line-height: 0px;
      color: getvar($colors, "neutral", "900");
    }
  }
}
